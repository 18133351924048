<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    append-to-body
    class="missionPart"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="mini"
      ref="dataForm"
      @keyup.enter.native="checkMode"
      label-width="120px"
    >
      <div class="tablePart">
        <el-form-item label="ID" prop="id" v-if="dataForm.id">
          <el-input
            v-model="dataForm.id"
            :disabled="true"
            placeholder="ID"
          ></el-input>
        </el-form-item>
        <el-form-item label="智能取餐柜名称" prop="name">
          <el-input
            v-model="dataForm.name"
            :disabled="disabled"
            placeholder="智能取餐柜名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="智能取餐柜编号" prop="sn">
          <el-input
            v-model="dataForm.sn"
            :disabled="disabled"
            placeholder="智能取餐柜编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="智能取餐柜机器码" prop="deviceId">
          <el-select
            v-model="dataForm.deviceId"
            :disabled="disabled"
            filterable
            placeholder="智能取餐柜机器码"
          >
            <el-option
              v-for="item in machineCodeList"
              :key="item.id"
              :label="item.machineCode"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="isUse" label="">
          <el-checkbox v-model="dataForm.isUse" :disabled="disabled"
            >是否使用</el-checkbox
          >
        </el-form-item>
        <el-form-item prop="isGroup" label="">
          <el-checkbox v-model="dataForm.isGroup" :disabled="disabled"
            >是否团餐</el-checkbox
          >
        </el-form-item>
        <el-form-item label="智能取餐柜规格" prop="specId">
          <el-select
            v-model="dataForm.specId"
            :disabled="disabled"
            placeholder="智能取餐柜规格"
          >
            <el-option
              v-for="item in specList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="智能取餐柜地址" prop="address" class="inlineBtn">
          <el-input
            v-model="dataForm.address"
            :disabled="disabled"
            placeholder="智能取餐柜地址"
            @change="tranAddress"
          />
          <div class="mapBlock">
            <el-button @click="openForm" type="info" v-if="!chooseVisible"
              >打开地图</el-button
            >
            <el-button @click="closeForm" type="warning" v-else
              >关闭地图</el-button
            >
            <map-choose
              ref="mapchoose"
              v-if="chooseVisible"
              @newAddress="getNewAddress"
              class="mapWay"
            />
          </div>
        </el-form-item>

        <el-form-item label="门牌号" prop="streetAddress">
          <el-input
            v-model="dataForm.streetAddress"
            :disabled="disabled"
            placeholder="门牌号"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="contact">
          <el-input
            v-model="dataForm.contact"
            :disabled="disabled"
            placeholder="联系方式"
          ></el-input>
        </el-form-item>
        <el-form-item label="保证金" prop="deposit">
          <el-input-number
            v-model="dataForm.deposit"
            :min="0"
            controls-position="right"
            :disabled="disabled"
            placeholder="保证金"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="投资金额" prop="investment">
          <el-input-number
            v-model="dataForm.investment"
            :min="0"
            controls-position="right"
            :disabled="disabled"
            placeholder="投资金额"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="智能取餐柜图片" prop="imageUrl">
          <el-upload
            class="avatar-uploader"
            :action="url"
            :show-file-list="false"
            :on-success="successHandle"
            :before-upload="beforeUploadHandle"
          >
            <img
              v-if="dataForm.imageUrl"
              :src="dataForm.imageUrl"
              style="max-width: 100%"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { normal } from '@/mixins';
import { getMachineCodeList, getSpecList } from '@/utils/options';
import mapChoose from '@/components/addressChoose';
export default {
  mixins: [normal],
  components: { mapChoose },
  data() {
    return {
      disabled: false,
      visible: false,
      chooseVisible: false,
      url: '',
      machineCodeList: [],
      specList: [],
      markersLayer: null,
      geometries: null,
      dataForm: {
        id: 0,
        name: '',
        sn: '',
        deviceId: '',
        isGroup: true,
        isUse: true,
        imageUrl: '',
        specId: '',
        streetAddress: '',
        deposit: '',
        investment: '',
        contact: '',
        address: '',
        longitude: null,
        latitude: null,
      },
      dataRule: {
        name: [
          { required: true, message: '供应商名字不能为空', trigger: 'blur' },
        ],
        sn: [
          {
            required: true,
            message: '智能取餐柜编号不能为空',
            trigger: 'blur',
          },
        ],
        specId: [
          {
            required: true,
            message: '智能取餐柜规格不能为空',
            trigger: 'blur',
          },
        ],
        address: [
          {
            required: true,
            message: '智能取餐柜地址不能为空',
            trigger: 'blur',
          },
        ],
        deposit: [
          {
            required: true,
            message: '保证金不能为空',
            trigger: 'blur',
          },
        ],
        investment: [
          {
            required: true,
            message: '投资金额不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.getMachineCodeList();
    this.getSpecList();
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.url =
        this.$http.BASE_URL +
        `/sys/oss/upload?token=${this.$cookie.get('token')}`;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (id) {
          this.$http({
            url: `/cc/freezer/id/query/${id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.dataForm = data.data;
              // if (data.data.address) {
              //   this.getAddress(data.data.address);
              // } else {
              //   this.getAddress('佛山市');
              // }
            }
          });
        } else {
          // this.getAddress('佛山市');
        }
      });
    },
    checkMode() {},
    openForm() {
      this.chooseVisible = true;
      this.$nextTick(() => {
        if (this.dataForm.address) {
          this.$refs.mapchoose.getAddress(this.dataForm.address);
        } else {
          this.$refs.mapchoose.getAddress('佛山市');
        }
      });
    },
    closeForm() {
      this.chooseVisible = false;
    },
    getNewAddress(address) {
      this.dataForm.address = address;
    },
    async tranAddress(data) {
      if (this.chooseVisible) {
        const dataForm = await this.$refs.mapchoose.getAddress(data);
        this.dataForm.latitude = dataForm.latitude;
        this.dataForm.longitude = dataForm.longitude;
      } else {
        this.openForm();
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/freezer/${!this.dataForm.id ? 'save' : 'id/update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
    beforeUploadHandle(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (
        file.type !== 'image/jpg' &&
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/gif'
      ) {
        this.$message.error('只支持jpg、png、gif格式的图片！');
        return false;
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过10MB哦!');
        return false;
      }
    },
    // 上传成功
    successHandle(response, file, fileList) {
      if (response && response.code === 0) {
        this.dataForm.imageUrl = response.title;
      } else {
        this.$message.error(response.msg);
      }
    },
    getMachineCodeList() {
      getMachineCodeList().then(({ data }) => {
        if (data && data.status === 0) {
          this.machineCodeList = data.data.items;
        }
      });
    },
    getSpecList() {
      getSpecList().then(({ data }) => {
        if (data && data.status === 0) {
          this.specList = data.data.items;
        }
      });
    },
  },
};
</script>
